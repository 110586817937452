<template>
<div class="container">
    <NavBottom />

    <div class="cont">
        <div class="as-wrp">
            <template v-if="urlType == 0">
                <a  href="https://www.visasiv.cn/inquiry5/ajax.html">责任险缴费查询>></a>
                <a  href="https://www.visasiv.cn/inquiry/ajax.html">学生险缴费查询>></a>
            </template>
            <template v-if="urlType == 1">
                <!-- <router-link to="/stuMedInvoice">医保发票领取>></router-link> -->
                <router-link to="/stuInvoice">学生险发票领取>></router-link>
            </template>
        </div>
    </div>
</div>
</template>

<script>
    export default {
        data(){
            return {
                urlType:0
            }
        },
        created(){
            this.urlType = this.$route.params.type
        }
    }
</script>

<style scoped>
.container{
    background-color: #fff;
    height: 100vh;

}
.cont{
    height: 100%;
    background:url(../assets/linBg.jpg) no-repeat center top / 100% auto;
}
.as-wrp{
    position:absolute;
    top: 45vw;
    left: 5%;
    width:90%;
    margin: 20px 0;
    background-color: rgba(255,255,255, 0.6);
    border-radius: 12px;
    box-shadow: 0 0 6px #ccc
}
.as-wrp a{
    text-align:center;
    display: block;
    padding: 0 12px;
    border-radius: 12px;
    width:80%;
    margin: 30px auto;
    background-image: linear-gradient(to right,#3188e2,#07d3e4);
    color: #fff;
    font-size:22px;
    line-height: 2;
}
</style>